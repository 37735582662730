import React from "react";
import { Input } from "theme-ui";
import PropTypes from "prop-types";
import { useAlert } from "../alert";

const CopyTextField = React.memo(({ id, style, ...restprops }) => {
  const { showAlert } = useAlert();

  return (
    <Input
      {...restprops}
      style={{ ...style, cursor: "pointer" }}
      readOnly
      id={id}
      onClick={() => {
        /* Get the text field */
        const copyText = document.getElementById(id);

        /* Select the text field */
        copyText.select();

        /* Copy the text inside the text field */
        document.execCommand("copy");

        /* Alert the copied text */
        showAlert({
          title: "Copied to Clipboard!",
          message: " ",
        });
      }}
    />
  );
});

CopyTextField.propTypes = {
  id: PropTypes.string.isRequired,
  restProps: PropTypes.shape({}),
  style: PropTypes.shape({}),
};

CopyTextField.defaultProps = {
  style: {},
  restProps: {},
};

export default CopyTextField;
