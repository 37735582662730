/** @jsx jsx */
import { jsx } from "theme-ui";

import DefaultLayout from "../../../layouts/default";
import KanhaVolunteeringAdmin from "../../../components/AshramOffice/KanhaVolunteering/admin1";

const MeSearch = () => {
  return (
    <DefaultLayout>
      <KanhaVolunteeringAdmin />
    </DefaultLayout>
  );
};

export default MeSearch;
