import { get } from "./lodash";

const copyKeys = (dict, keys = [], keyMap = {}) => {
  const x = {};
  if (!!keyMap && Object.keys(keyMap).length > 0) {
    (typeof keys === "string" ? keys.split(",") : keys).forEach((k) => {
      x[get(keyMap, k, k)] = dict[k];
    });
  } else {
    (typeof keys === "string" ? keys.split(",") : keys).forEach((k) => {
      x[k] = dict[k];
    });
  }
  return x;
};

export default copyKeys;
