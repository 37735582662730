/** @jsx jsx */
import { jsx, Spinner, Text, Card, Badge } from "theme-ui";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFirestore } from "react-redux-firebase";

import { get, noop } from "sites-common/utils/lodash";
import {
  doFormatShortDate,
  formatSys2OdooDate,
} from "@heartfulnessinstitute/react-hfn-forms/dist/formatting";
import fabricStyles from "sites-common/utils/fabricStyles";
import useMyBoolean from "sites-common/hooks/useMyBoolean";
import {
  DefaultButton,
  Panel,
  PanelType,
  Stack,
  StackItem,
} from "office-ui-fabric-react";
import DynamicForm from "@heartfulnessinstitute/react-hfn-forms/dist/DynamicForm";
import PropTypes from "prop-types";
import Records from "gatsby-plugin-hfn-profile/components/FirestoreCloudFunctions/download";
import CopyTextField from "gatsby-plugin-hfn-profile/components/CopyTextField";
import KanhaVolunteeringAdminPOC from "./admin2pocs";
import { aimsFlds, visitFlds } from "./fields";
import { collection_userrecords_proxy } from "./adminconstants";
import { ageOptions } from "./ageOptions";

const collection = `AOKanhaVolunteeringAdmin`;
const storeAs = collection;
const listenerSettings1 = {
  collection,
  storeAs,
};

const today = formatSys2OdooDate(new Date());

const funcVisitShow = (key) => (r) =>
  r.visit_flds?.includes(key) ? {} : { hide: true };

const funcRestrictionsShow = (key) => (r) =>
  r.restrictions?.includes(key) ? {} : { hide: true };

const formFields = [
  {
    name: "date_added",
    label: "Date Added",
    type: "date",
  },
  {
    name: "active",
    label:
      "Active (if not active, disables the volunteer form for new entries / any updates, changes)",
    type: "toggle",
  },
  {
    name: "title",
    label: "Title (Displayed on Volunteer Form)",
    type: "text",
  },
  // {
  //   name: "type",
  //   label: "Note Type",
  //   type: "select",
  //   options: [
  //     { name: "text", label: "Plain Text" },
  //     { name: "html", label: "HTML" },
  //   ],
  // },
  {
    name: "note",
    label: "Note (Displayed on Volunteer Form)",
    props: { multiline: true, rows: 5 },
  },
  {
    name: "use_secret",
    label: "Control Access to Volunteer Form with a Secret Code",
    type: "toggle",
  },
  {
    name: "secrets",
    label: "Secret Codes",
    listField: true,
    dynamic: [["hide", [":use_secret", "!=", true]]],
  },

  {
    name: "break0",
    type: "statichtml",
    label: "<br/>Select Fields to Capture for Each Volunteer<br/>",
  },

  {
    name: "aims_flds",
    label: "Person Fields",
    type: "multiselect",
    options: aimsFlds({ all: 1 }),
  },
  {
    name: "visit_flds",
    label: "Visit Related Fields",
    type: "multiselect",
    options: visitFlds({ all: 1 }),
  },

  {
    name: "break1",
    type: "statichtml",
    label: "<br/>",
    dynamic: funcVisitShow("arrival"),
  },
  {
    name: "arrival_mindate",
    label: "Allow Arrival only from",
    type: "date",
    dynamic: funcVisitShow("arrival"),
    props: {
      min: 120,
      gridInline: 200,
    },
  },
  {
    name: "arrival_maxdate",
    label: "Allow Arrival upto",
    type: "date",
    dynamic: funcVisitShow("arrival"),
    props: {
      min: 120,
      gridInline: 200,
    },
  },
  {
    name: "break2",
    type: "statichtml",
    label: "<br/>",
    dynamic: funcVisitShow("departure"),
  },
  {
    name: "departure_mindate",
    label: "Allow Departure from",
    type: "date",
    dynamic: funcVisitShow("departure"),
    props: {
      min: 120,
      gridInline: 200,
    },
  },
  {
    name: "departure_maxdate",
    label: "Allow Departure upto",
    type: "date",
    dynamic: funcVisitShow("departure"),
    props: {
      min: 120,
      gridInline: 200,
    },
  },
  {
    name: "break3",
    type: "statichtml",
    label: "<br/>",
    dynamic: funcVisitShow("stay"),
  },
  {
    name: "stay_options",
    label: "Stay Preferences (Selection on Volunteer Form)",
    listField: true,
    dynamic: funcVisitShow("stay"),
  },
  {
    name: "break4",
    type: "statichtml",
    label: "<br/>",
    dynamic: funcVisitShow("vlntr_area"),
  },

  {
    name: "vlntr_area_options",
    label:
      "Volunteer Departments (Selection on Volunteer Form; User can select multiple of these)",
    listField: true,
    dynamic: funcVisitShow("vlntr_area"),
  },
  {
    name: "vlntr_area_allow_multiple",
    label: "Allow a volunteer to select multiple departments",
    type: "toggle",
    dynamic: funcVisitShow("vlntr_area"),
  },
  {
    name: "restrictions",
    label: "Add Restrictions for Volunteer Sign-ups",
    type: "multiselect",
    options: [{ name: "age", label: "Age" }],
    props: { required: false },
  },
  {
    name: "age_groups",
    label: "Allowed Age Groups for Volunteers",
    type: "multiselect",
    options: ageOptions,
    dynamic: funcRestrictionsShow("age"),
  },
  // {
  //   name: "age_max",
  //   label: "Maximum Age",
  //   type: "number",
  //   props: {description: "add maximum age for volunteers to sign-up"},
  //   dynamic: funcRestrictionsShow("age"),
  //   props: {
  //     min: 120,
  //     gridInline: 200,
  //   },
  // },
  // props: {
  //   add: true, delete: true ,  required: false
  // },
  // subFormFields: [
  //   {name: "fld", label: "Field", },
  //   {name: "op", label: "Condition", },
  //   {name: "val", label: "Value", }
  // ],
  // type: "jsonkv",
  // "props": {
  //   "required": false,
  //   "formfields": [
  //     { "name": "msg", "label": "Error Message" },
  //     {
  //       "name": "conds",
  //       "label": "Conditions",
  //       "type": "jsonfield"
  //     }
  //   ],
  //   "defaults": {
  //     "required": true
  //   }
  // }

  // {
  //   name: "vlntr_heads",
  //   label: "Volunteer Departments and Heads",
  //   type: "jsonkv",

  //   "props": {
  //     "required": false,
  //     "formfields": [
  //       { "name": "msg", "label": "Error Message" },
  //       {
  //         "name": "conds",
  //         "label": "Conditions",
  //         "type": "jsonfield"
  //       }
  //     ],
  //     "defaults": {
  //       "required": true
  //     }
  //   }
  // },

  {
    name: "hideit",
    label: "Do not show this event (Hide this event on volunteer form)",
    type: "toggle",
  },
];

const getHost = () =>
  typeof window !== "undefined"
    ? `${window.location.protocol}//${window.location.host}`
    : "";

const formValidator = (r) => {
  const errors = [];
  if (r.vlntr_area_options && r.vlntr_area_options.length < 2) {
    errors.push("Minimum 2 options required for Volunteer Departments");
  }
  if (r.stay_options && r.stay_options.length < 2) {
    errors.push("Minimum 2 options required for Stay Preferences");
  }
  return errors ? errors.join("<br/>") : false;
};

const RenderAnnouncement = ({ a }) => {
  const firestore = useFirestore();

  const [editMode, { setTrue: setEditMode, setFalse: clearEditMode }] =
    useMyBoolean(false);

  const [poc, { setTrue: setPOC, setFalse: clearPOC }] = useMyBoolean(false);

  const onSubmit = useCallback(
    (data, sCb, fCb) => {
      firestore
        .set(
          `${collection}/${a.id}`,
          {
            ...data,
            date_added: today,
          },
          { merge: true }
        )
        .then(() => {
          sCb();
        })
        .catch((e) => {
          fCb(e.toString());
        });
    },
    [a.id, firestore]
  );

  console.log({ restrictions: [], ...a });

  return (
    <div sx={{ m: 3, p: 3 }}>
      {editMode && (
        <Panel
          type={PanelType.medium}
          headerText={`Editing ${a.title}`}
          onDismiss={clearEditMode}
          isOpen
        >
          <div sx={{ bg: "#88C6ED", p: 2 }}>
            <DynamicForm
              formFields={formFields}
              formValidator={formValidator}
              defaultValues={{ restrictions: [], ...a }}
              onSubmit={onSubmit}
              onDiscard={clearEditMode}
            />
          </div>
        </Panel>
      )}
      {poc && (
        <Panel
          type={PanelType.customNear}
          headerText={`POCs ${a.title}`}
          onDismiss={clearPOC}
          isOpen
        >
          <KanhaVolunteeringAdminPOC
            adminConfig={a}
            onSaveAdminConfig={onSubmit}
          />
        </Panel>
      )}
      <Stack>
        <div>
          <Text variant="description">{doFormatShortDate(a.date_added)}</Text>
          {/* <Badge variant="inverted" sx={{ ml: 2, mr: 4 }}>
          {a.type === "html" ? "HTML" : "Plain Text"}
        </Badge> */}
        </div>
      </Stack>

      <Card
        sx={{
          boxShadow: "0px 3px 6px #00000029",
          opacity: 1,
          borderRadius: "2px",
          borderColor: "background",
          backgroundColor: "background",
          p: 2,
        }}
      >
        <Stack
          horizontal
          wrap
          verticalAlign="center"
          tokens={{ childrenGap: 8 }}
        >
          <StackItem>
            {/* <Text variant="printFormLabel">Title:</Text> */}
            {a.title}
            <Badge sx={{ ml: 2 }} variant={a.active ? "info" : "danger"}>
              {a.active ? "Active" : "Disabled"}{" "}
            </Badge>
            {a.hideit && (
              <Badge sx={{ ml: 2 }} variant={a.active ? "info" : "danger"}>
                Hidden on UI
              </Badge>
            )}
          </StackItem>
          <StackItem grow>
            <CopyTextField
              key={a.id}
              id={a.id}
              value={`${getHost()}/forms/kanha-volunteering/?q=${a.id}`}
            />
          </StackItem>
          <Stack horizontal tokens={{ childrenGap: 8 }} sx={{ mt: 3 }}>
            <Records
              dbcode={collection_userrecords_proxy}
              fields={[...a.aims_flds, ...a.visit_flds]}
              filters={{ oppy: a.id }}
              styles={fabricStyles.buttonStyles.danger}
              text="Download CSV"
              iconProps={{ iconName: "Download" }}
            />
            <DefaultButton
              styles={fabricStyles.buttonStyles.blue}
              text="POCs"
              iconProps={{ iconName: "People" }}
              onClick={setPOC}
            />
            <DefaultButton
              styles={fabricStyles.buttonStyles.warning}
              text="config"
              iconProps={{ iconName: "Edit" }}
              onClick={setEditMode}
            />
          </Stack>
        </Stack>
        {/* <hr /> */}
        {/* <Text variant="printFormLabel">Note:</Text> */}
        {/* {a.type === "html" ? (
          <div dangerouslySetInnerHTML={{ __html: a.note }} />
        ) : (
          <div sx={{ fontSize: "0.8em" }}>{a.note}</div>
        )} */}
      </Card>
    </div>
  );
};

RenderAnnouncement.propTypes = {
  a: PropTypes.shape({
    id: PropTypes.string,
    note: PropTypes.string,
    date_added: PropTypes.string,
    type: PropTypes.string,
    aims_flds: PropTypes.arrayOf(PropTypes.string),
    visit_flds: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string,
    active: PropTypes.bool,
    hideit: PropTypes.bool,
  }).isRequired,
};

const NewRecord = ({ onDiscard }) => {
  const firestore = useFirestore();

  const onSubmit = useCallback(
    (data, sCb, fCb) => {
      firestore
        .add(`${collection}`, {
          ...data,
        })
        .then(() => {
          sCb();
        })
        .catch((e) => {
          fCb(e.toString());
        });
    },
    [firestore]
  );

  return (
    <div sx={{ bg: "#88C6ED", p: 2 }}>
      <DynamicForm
        formFields={formFields}
        defaultValues={{
          date_added: today,
          aims_flds: ["name", "ref", "city", "mobile"],
          restrictions: [],
        }}
        onSubmit={onSubmit}
        onDiscard={onDiscard}
      />
    </div>
  );
};

NewRecord.propTypes = {
  onDiscard: PropTypes.func,
};
NewRecord.defaultProps = {
  onDiscard: noop,
};
function KanhaVolunteeringAdmin() {
  const firestore = useFirestore();
  const announcements = useSelector((state) =>
    get(state.firestore.ordered, [storeAs], [])
  );
  const [limit] = useState(20);
  const [add, { setTrue: setAdd, setFalse: clearAdd }] = useMyBoolean(false);

  useEffect(() => {
    const listenerSettings = { ...listenerSettings1, limit };
    firestore.setListener(listenerSettings);
    return function cleanup() {
      firestore.unsetListener(listenerSettings);
    };
  }, [firestore, limit]);

  return (
    <div>
      <Stack horizontalAlign="center">
        <b>Ashram Office - Volunteering Opportunities</b>
        <Text variant="subtitle">Admin Console</Text>
        <div>
          Link to Volunteer Registration Page for all events:&nbsp;
          <a target="_blank" href="/forms/kanha-volunteering/all/">
            Click Here
          </a>
        </div>
      </Stack>
      {announcements === undefined && <Spinner />}
      {announcements !== undefined && (
        <div>
          <div>
            <DefaultButton
              sx={{ mt: 4, ml: 5 }}
              iconProps={{ iconName: "Add" }}
              text="New Volunteering Opportunity"
              onClick={setAdd}
            />

            {add && (
              <Panel
                type={PanelType.medium}
                headerText="New Opportunity"
                onDismiss={clearAdd}
                isOpen
              >
                <NewRecord onDiscard={clearAdd} />
              </Panel>
            )}
          </div>

          {announcements.map((a) => {
            return (
              <div sx={{ my: 3 }}>
                <RenderAnnouncement a={a} />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

KanhaVolunteeringAdmin.propTypes = {};
KanhaVolunteeringAdmin.defaultProps = {};

export default KanhaVolunteeringAdmin;
