/** @jsx jsx */
import { jsx } from "theme-ui";
import Layout from "../components/SiteLayout";

const RootWrapper = require("sites-common/gatsby-utils/WrapRoot").default;

const DefaultLayout = (rprops) => {
  return (
    <RootWrapper
      element={
        <Layout>
          <div {...rprops} />
        </Layout>
      }
    />
  );
};
export default DefaultLayout;
