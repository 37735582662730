/** @jsx jsx */
import { Badge, jsx } from "theme-ui";

import PropTypes from "prop-types";
import PeopleSelector from "gatsby-plugin-hfn-profile/components/PeopleSelector";
import { useCallback, useEffect, useState } from "react";
import { DynamicField } from "@heartfulnessinstitute/react-hfn-forms/dist/DynamicForm";
import { DefaultButton, IconButton, Stack } from "office-ui-fabric-react";
import { doFormatTitle } from "@heartfulnessinstitute/react-hfn-forms/dist/formatting";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
} from "sites-common/components/ui/DivTable";
import copyKeys from "sites-common/utils/copyKeys";
import { overall } from "./adminconstants";

const FieldAdd = ({ onAdd }) => {
  const [val, setVal] = useState("");

  const onClick = useCallback(() => {
    onAdd(doFormatTitle(val));
    setVal("");
  }, [val, setVal, onAdd]);

  return (
    <Stack horizontal sx={{ p: 1, border: "1px solid grey" }}>
      <DynamicField
        props={{ placeholder: "department name" }}
        name="name"
        value={val}
        onChange={setVal}
      />
      <DefaultButton text="Add" onClick={onClick} />
    </Stack>
  );
};
FieldAdd.propTypes = {
  onAdd: PropTypes.func.isRequired,
};

function KanhaVolunteeringAdminPOC({ onSaveAdminConfig, adminConfig }) {
  const d = adminConfig?.pocs || {};
  const [depts, setDepts] = useState(d);
  const [prevDepts, setPrevDepts] = useState(d);

  const dirty = JSON.stringify(depts) !== JSON.stringify(prevDepts);

  const saveConfig = useCallback(() => {
    onSaveAdminConfig({ pocs: depts }, () => setPrevDepts(depts), alert);
  }, [depts, onSaveAdminConfig, setPrevDepts]);

  const addDept = useCallback(
    (val, title) => {
      const v = val.toLowerCase().trim().replace(/ +/g, " ");
      setDepts((prev) =>
        v in prev ? prev : { [v]: { dept: title || doFormatTitle(v) }, ...prev }
      );
    },
    [setDepts]
  );

  // const deleteDept = useCallback((val) => {
  //   setDepts(prev => {let n = {...prev}; delete n[val]; return n });
  // })

  const addPOC = useCallback(
    (dept, leads) => {
      const leads1 = Object.fromEntries(
        Object.entries(leads)
          .map(([k, v]) => [
            k,
            copyKeys(v, ["name", "mobile", "city", "user_firebase_uid", "id"], {
              user_firebase_uid: "uid",
              id: "aims_id",
            }),
          ])
          .map(([k, v]) => [k, { ...v, inactive: false }])
      );
      setDepts((prev) => ({
        ...prev,
        [dept]: {
          ...prev[dept],
          dept: doFormatTitle(dept),
          leads: { ...prev[dept].leads, ...leads1 },
        },
      }));
    },
    [setDepts]
  );

  const deletePOC = useCallback(
    (dept, aims_id) => {
      setDepts((prev) => ({
        ...prev,
        [dept]: {
          ...prev[dept],
          dept: doFormatTitle(dept),
          leads: { ...prev[dept].leads, [aims_id]: { inactive: true } },
        },
      }));
    },
    [setDepts]
  );
  const setDeptKey = useCallback(
    (dept, key, val) => {
      setDepts((prev) => ({
        ...prev,
        [dept]: {
          ...prev[dept],
          dept: doFormatTitle(dept),
          [key]: val,
        },
      }));
    },
    [setDepts]
  );

  useEffect(() => {
    if (adminConfig && adminConfig?.vlntr_area_options) {
      addDept(overall, overall);
      adminConfig?.vlntr_area_options.forEach((val) =>
        addDept(val, `${doFormatTitle(val)}`)
      );
    }
  }, [adminConfig, addDept]);

  return (
    <div sx={{ bg: "#b2d3c2", p: 2 }}>
      {dirty && <Badge> Unsaved Changes. Click to Save</Badge>}
      <br />
      <DefaultButton
        primary
        iconProps={{ iconName: "Save" }}
        disabled={!dirty}
        text="Save"
        onClick={saveConfig}
      />
      <br />
      <br />
      <Table>
        <TableRow>
          <TableCell>
            <b>Overall Supervision / Co-organizers</b>
            <br /> (can view/download all volunteers data){" "}
          </TableCell>
        </TableRow>

        {Object.entries(depts)
          .filter(([, row]) => row.dept === overall)
          .map(([dept, row]) => (
            <TableRow>
              <TableCell>
                <table>
                  {!!row?.leads &&
                    Object.values(row.leads)
                      .filter((lead) => !lead.inactive)
                      .map((lead) => {
                        return (
                          <tr>
                            <td>{lead.name}</td>
                            <td sx={{ px: 4 }}>{lead.city}</td>
                            <td>{lead.mobile}</td>
                            <td>
                              <IconButton
                                iconProps={{ iconName: "Trash" }}
                                title={`Delete ${lead.name} as Co-Organizer for ${row.dept}`}
                                onClick={() => deletePOC(dept, lead.aims_id)}
                              />
                            </td>
                          </tr>
                        );
                      })}
                </table>

                <PeopleSelector
                  btnType="action"
                  btnProps={{
                    text: "Add Co-Organizer",
                    iconProps: { iconName: "Add" },
                  }}
                  onSelectionDone={(leads) => addPOC(dept, leads)}
                  showMyself={false}
                />
              </TableCell>
            </TableRow>
          ))}
      </Table>

      <Table sx={{ mt: 3 }}>
        <TableRow>
          <TableHead>Department</TableHead>
          <TableHead>POCs</TableHead>
          <TableHead>No. of Volunteers Required</TableHead>
          <TableHead />
        </TableRow>
        {Object.entries(depts)
          .filter(([, row]) => row.dept !== overall)
          .sort((a, b) => a[0] > b[0])
          .map(([dept, row]) => (
            <TableRow>
              <TableCell sx={{ fontWeight: 700 }}>
                {row.dept === overall
                  ? "Overall Supervision / Co-organizers"
                  : row.dept}

                {JSON.stringify(depts[dept]) !==
                  JSON.stringify(prevDepts[dept]) && (
                  <Badge> *Unsaved Changes </Badge>
                )}
              </TableCell>
              <TableCell>
                <table>
                  {!!row?.leads &&
                    Object.values(row.leads)
                      .filter((lead) => !lead.inactive)
                      .map((lead) => {
                        return (
                          <tr>
                            <td>{lead.name}</td>
                            <td sx={{ px: 4 }}>{lead.city}</td>
                            <td>{lead.mobile}</td>
                            <td>
                              <IconButton
                                iconProps={{ iconName: "Trash" }}
                                title={`Delete ${lead.name}as POC for ${row.dept}`}
                                onClick={() => deletePOC(dept, lead.aims_id)}
                              />
                            </td>
                          </tr>
                        );
                      })}
                </table>
              </TableCell>
              <TableCell>
                <DynamicField
                  name="num"
                  value={row?.num || ""}
                  onChange={(n) => setDeptKey(dept, "num", n)}
                />
              </TableCell>
              <TableCell>
                <PeopleSelector
                  btnType="action"
                  btnProps={{ text: "Add POC", iconProps: { iconName: "Add" } }}
                  onSelectionDone={(leads) => addPOC(dept, leads)}
                  showMyself={false}
                />

                {/* <ConfirmButton
                title={"Deleting " + row.dept}
                message="Are you sure you want to continue?"
                onClick={deleteDept}
              > */}
                {/* <IconButton
                  iconProps={{ iconName: "Trash" }}
                  title={"Delete " + row.dept}
                  onClick={() => deleteDept(dept)}
                /> */}
                {/* </ConfirmButton> */}
              </TableCell>
            </TableRow>
          ))}
      </Table>
      <FieldAdd onAdd={addDept} />

      <br />
      <br />
      {dirty && <Badge> Unsaved Changes. Click to Save</Badge>}
      <br />
      <DefaultButton
        primary
        iconProps={{ iconName: "Save" }}
        disabled={!dirty}
        text="Save"
        onClick={saveConfig}
      />
      <br />
      <br />
    </div>
  );
}

KanhaVolunteeringAdminPOC.propTypes = {
  onSaveAdminConfig: PropTypes.func.isRequired,
  adminConfig: PropTypes.shape({
    pocs: PropTypes.shape({}),
    vlntr_area_options: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};
KanhaVolunteeringAdminPOC.defaultProps = {};

export default KanhaVolunteeringAdminPOC;
