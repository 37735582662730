/** @jsx jsx */
import { DefaultButton } from "office-ui-fabric-react";
import getMandatoryEnv from "sites-common/utils/getMandatoryEnv";
import { jsx } from "theme-ui";
import PropTypes from "prop-types";
import { useFirebase } from "react-redux-firebase";
import { encode2 } from "../../../my/src/lib/encodedecode";

const { cloudFunctions } = getMandatoryEnv(["cloudFunctions"]);

const authenticatedRequest = (url, firebase) => {
  firebase
    .auth()
    .currentUser.getIdToken()
    .then((authToken) => {
      // console.log(
      //   "Sending request to",
      //   url,
      //   "with ID token in Authorization header."
      // );

      const headers = { Authorization: `Bearer ${authToken}` };

      fetch(url(authToken), { headers })
        .then((res) => res.blob())
        .then((blob) => {
          const file = window.URL.createObjectURL(blob);
          window.location.assign(file);
        });
    });
};

// fields -- field1,field2,...
// filters -- either object {"field1": "val1", "field2": "val2"}
//            or Array of triplets [ ["field1", "==", "val1" ], ["field2", "!=", "val2" ],
//                                           ['regions', 'array-contains-any', ['west_coast', 'east_coast'] ]

function Records({ btnText, dbcode, fields, filters, ...props }) {
  const url = (token) =>
    `${cloudFunctions.csvdump}${dbcode}?fields=${fields.join(
      ","
    )}&code=${encodeURIComponent(
      encode2(
        token,
        !Array.isArray(filters)
          ? JSON.stringify(
              Object.entries(filters).map(([k, v]) => [k, "==", v])
            )
          : JSON.stringify(filters)
      )
    )}`;

  const error = Array.isArray(filters) || typeof filters === "object";

  const firebase = useFirebase();

  return (
    <DefaultButton
      target="_blank"
      text={error ? "ERROR IN CONFIG" : btnText}
      {...props}
      onClick={() => authenticatedRequest(url, firebase)}
    />
  );
}
Records.propTypes = {
  btnText: PropTypes.string,
  dbcode: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(PropTypes.string).isRequired,
  filters: PropTypes.oneOfType(
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.string)
  ).isRequired,
};

Records.defaultProps = {
  btnText: "Download All Records",
};

export default Records;
